.header-wrapper {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    height         : 100px;
    background     : transparent;
    padding        : 38px 54px 23px 51px;

    .logo {
        line-height: 39px;
        font-size  : 24px;
    }

    .profile {
        height     : 39px;
        line-height: 39px;
        font-size  : 16px;

        .avatar {
            margin-left  : 7px;
            margin-bottom: 8px;
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 53px 16px 18px 24px;

        .logo {
            line-height: 29px;
        }

        .profile {
            .avatar {
                margin-left  : 0;
                margin-bottom: 0;
            }
        }


    }
}