.delivery-wrapper {
    padding: 52px 52px 32px;

    .order-content-wrap {
        display: flex;

        .order-params-wrapper {
            width: 450px;

            .ant-form-item {
                margin: 0;

                .ant-form-item-label {
                    padding: 0;
                }
            }

            .quantity-wrapper {
                display    : flex;
                align-items: flex-end;
                height     : 101px;

                .quantity-input {
                    margin-left: 28px;
                }

                .ant-form-item {
                    height: auto;
                }
            }

            .address-wrap {
                margin-top: 40px;
            }

            .contact-detail-wrap {
                margin-top: 40px;

                .ant-form-item:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }

        .order-detail-wrapper {
            width: 100%;
            margin-left: 94px;
        }
    }
}