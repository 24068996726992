@import "~@styles/variables.scss";

#choose-form {
    display        : flex;
    justify-content: start;
    height         : 100%;
    color          : $fontBlack;

    h4,
    h5,
    .ant-radio-button-wrapper {
        color: $fontBlack;
    }

    .choose-col-one {
        height    : 100%;
        flex-grow : 2;
        background: transparent;
        padding   : 52px 54px 52px 52px;

        .ant-form-item {
            margin: 0;

            .ant-form-item-label {
                padding: 0;
            }
        }

        .title {
            font-weight: 800;
            font-size  : 40px;
            line-height: 43px;
        }

        .purpose-form-item {
            margin-top: 40px;
        }

        .date-time-wrapper {
            display: flex;

            .date-time-divider {
                width: 16px;
            }
        }
    }

    .choose-col-two {
        width            : 438px;
        height           : 100%;
        min-height       : 736px;
        background       : url(~@assets/img/bg/column_bg.jpg);
        background-repeat: no-repeat;
        background-size  : cover;
        padding          : 52px 20px;

        .tutorial-msg {
            font-size: 14px;
            color    : $fontBlack;
        }

        .choose-test-wrapper {
            height: 588px;
            margin: 0;

            .choose-test-content {
                height: 588px;
            }
        }

        .title {
            font-weight  : 700;
            font-size    : 20px;
            line-height  : 24px;
            margin-bottom: 20px;
        }
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;

        .choose-col-one {
            height    : 100%;
            background: transparent;
            padding   : 0;

            .title {
                font-size  : 28px;
                line-height: 43px;
            }

            .purpose-form-item {
                margin-top: 24px;
            }

            .date-time-wrapper {
                .date-time-divider {
                    width: 12px;
                }
            }
        }

        .choose-col-two {
            height    : 100%;
            min-height: 100%;
            width     : 100%;
            background: transparent;
            padding   : 0;
            margin-top: 32px;

            .choose-test-wrapper {
                height: auto;
                margin: 0;

                .choose-test-content {
                    height: auto;
                }
            }
        }


        .mobile-button-wrap {
            display       : flex;
            flex-direction: column;
            padding        : 0 16px;
            width         : 100%;
        }
    }
}